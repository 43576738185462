/* eslint-disable no-unused-vars */
import { headerConfig, tokenConfig } from './helperFunc';
import axios from 'axios';
const config = headerConfig();

// machines log reducer actions

export const localhost = window.location.href.includes('localhost');

//export const urlPicker = 'https://core.thelaundro.com';

export const urlPicker =
	window.location.href.includes('da.thelaundro') ||
		window.location.href.includes('sandbox.thelaundro') ||
		window.location.href.includes('sb.')
		? 'https://sandbox.thelaundro.com'
		: window.location.href.includes('octopus')
			? 'https://laundro.octopus-laundry.com'
			: 'https://core.thelaundro.com';

export const checkDoPaymentUrl = localhost ? '/get/opid' : `${urlPicker}/get/opid`;

// ********************************* MACHINE LOGS  *********************************
// /api/distributor/v1/operator/fetch
const machineRoot = localhost ? '/api/logging/v1' : `${urlPicker}/api/logging/v1`;
const external = localhost ? '/api/distributor/v1/operator' : `${urlPicker}/api/distributor/v1/operator`;

export const fetchOperatorsAction = (distributorToken) => axios.get(`${external}/fetch`, tokenConfig(distributorToken));

export const machinesLogsAction = (operatorId, token) =>
	axios.get(`${machineRoot}/fetch?operatorId=${operatorId}`, tokenConfig(token));

export const addMachineLogAction = (formData, token) =>
	axios.post(`${machineRoot}/create`, formData, tokenConfig(token));

export const updateMachineLogAction = (formData, token) =>
	axios.put(`${machineRoot}/edit`, formData, tokenConfig(token));

// ********************************* MACHINE utilization  *********************************
const warrantyLink = localhost
	? '/api/maintenance-warranty/v1/machine/warranty'
	: `${urlPicker}/api/maintenance-warranty/v1/machine/warranty`;

export const addWarrantyAction = (formData, token) => axios.post(`${warrantyLink}/add`, formData, tokenConfig(token));

// machineUtilReducer reducer actions
const machineUtilRoot = localhost ? '/api/report/utilization/v1' : `${urlPicker}/api/report/utilization/v1`;

export const annualUtilReportAction = (reqBody, token) =>
	axios.post(`${machineUtilRoot}/annual`, reqBody, tokenConfig(token));

export const monthlyUtilReportAction = (reqBody, token) =>
	axios.post(`${machineUtilRoot}/monthly`, reqBody, tokenConfig(token));

export const outletDailyUtilAction = (year, month, outletId, operatorId, token) =>
	axios.get(
		`${machineUtilRoot}/daily?year=${year}&month=${month}&outletId=${outletId}&operatorId=${operatorId}`,
		tokenConfig(token)
	);

export const machineUtilTimeAnnualAction = (reqBody, token) =>
	axios.post(`${machineUtilRoot}/runtime`, reqBody, tokenConfig(token));

export const machineUtilTimeMonthAction = (reqBody, token) =>
	axios.post(`${machineUtilRoot}/runtime`, reqBody, tokenConfig(token));

export const annualTempPreferenceAction = (reqBody, token) =>
	axios.post(`${machineUtilRoot}/temperature`, reqBody, tokenConfig(token));

export const monthTempPreferenceAction = (reqBody, token) =>
	axios.post(`${machineUtilRoot}/temperature`, reqBody, tokenConfig(token));

// ********************************* outlet actions  *********************************
// outletReducer reducer actions
const outletRoot = localhost ? '/api/outlet/v1' : `${urlPicker}/api/outlet/v1`;

export const allOutletsAction = (operatorId, token) =>
	axios.get(`${outletRoot}/fetch?operatorId=${operatorId}`, tokenConfig(token));

export const allOutletsPingerLogAction = (operatorId, token) =>
	axios.get(`${outletRoot}/fetchPinger?operatorId=${operatorId}`, tokenConfig(token));

export const allOutletsDataOnlyAction = (operatorId, token) =>
	axios.get(`${outletRoot}/fetch/outlet_only?operatorId=${operatorId}`, tokenConfig(token));

export const allOutletsDataOnlyReportAction = (operatorId, token) =>
	axios.get(`${outletRoot}/fetch/outlet_only_report?operatorId=${operatorId}`, tokenConfig(token));

export const createOutletAction = (reqBody, token) => axios.post(`${outletRoot}/create`, reqBody, tokenConfig(token));
export const updateOutletAction = (reqBody, token) => axios.put(`${outletRoot}/edit`, reqBody, tokenConfig(token));

// ********************************* pricing strategy *********************************
export const updateOutletGroupingAction = (reqBody, token) =>
	axios.patch(`${outletRoot}/machine_grouping/edit`, reqBody, tokenConfig(token));

// priceSettingReducer
const priceRoot = localhost ? '/api/pricing/v1/strategy' : `${urlPicker}/api/pricing/v1/strategy`;
const priceRoot2 = localhost ? '/api/pricing/v2/strategy' : `${urlPicker}/api/pricing/v2/strategy`;

export const getPriceListAction = (operatorId, token) =>
	axios.get(`${priceRoot2}/fetch?operatorId=${operatorId}`, tokenConfig(token));

export const addPricingAction = (newPrice, token) => axios.post(`${priceRoot2}/add`, newPrice, tokenConfig(token));

export const assignPricingAction = (newPrice, token) => axios.put(`${priceRoot2}/assign`, newPrice, tokenConfig(token));

export const editPricingAction = (updateData, token) => axios.put(`${priceRoot2}/edit`, updateData, tokenConfig(token));

export const editPricingActionv2 = (updateData, token) => axios.put(`${priceRoot2}/secondary/edit`, updateData, tokenConfig(token));

export const deletePricingAction = (resData, token) => {
	return axios.delete(`${priceRoot2}/delete`, {
		...tokenConfig(token),
		data: resData,
	});
};

export const getModeListAction = (operatorId, token) =>
	axios.get(`${priceRoot2}/mode/fetch?operatorId=${operatorId}`, tokenConfig(token));

export const getModeListActionv2 = (dataPromo, token) => axios.post(`${priceRoot2}/mode/fetch2`, dataPromo, tokenConfig(token));

// ********************************* pricing promotion *********************************
// promotion actions /api/pricing/v1/promotion/
const promotionRoot = localhost ? '/api/pricing/v1/promotion' : `${urlPicker}/api/pricing/v1/promotion`;

export const getPromotionListAction = (operatorId, token) =>
	axios.get(`${promotionRoot}/fetch?operatorId=${operatorId}`, tokenConfig(token));

export const addPromotionAction = (newPrice, token) => axios.post(`${promotionRoot}/add`, newPrice, tokenConfig(token));

export const assignPromotionAction = (newPrice, token) =>
	axios.put(`${promotionRoot}/assign`, newPrice, tokenConfig(token));

export const editPromotionAction = (updateData, token) =>
	axios.put(`${promotionRoot}/edit`, updateData, tokenConfig(token));

export const deletePromotionAction = (id) => {
	return axios.delete(`${promotionRoot}/delete`, {
		...tokenConfig(),
		data: { id },
	});
};

// ********************************* pricing strategy *********************************

// dropServiceReducer
const priceDropRoot = localhost ? '/api/pricing/v1/dropservice' : `${urlPicker}/api/pricing/v1/dropservice`;

export const getDropPriceListAction = (operatorId, token) =>
	axios.get(`${priceDropRoot}/fetch?operatorId=${operatorId}`, tokenConfig(token));

export const addDropPricingAction = (newPrice, token) => axios.post(`${priceDropRoot}/add`, newPrice, tokenConfig(token));

export const assignDropPricingAction = (newPrice, token) => axios.put(`${priceDropRoot}/assign`, newPrice, tokenConfig(token));

export const editDropPricingAction = (updateData, token) => axios.put(`${priceDropRoot}/edit`, updateData, tokenConfig(token));

export const deleteDropPricingAction = (resData, token) => {
	return axios.delete(`${priceDropRoot}/delete`, {
		...tokenConfig(token),
		data: resData,
	});
};

// ********************************* remote machine *********************************
// remoteReducer  reducer actions
const remoteRoot = localhost ? '/api/machine/v1' : `${urlPicker}/api/machine/v1`;

export const remoteMachineAction = (operatorId = '', outletId = '', token, coin = '') =>
	axios.get(`${remoteRoot}/fetch?operatorId=${operatorId}&outletId=${outletId}&coin=${coin}`, tokenConfig(token));

export const remoteActivateAction = (values, token) =>
	axios.post(`${remoteRoot}/remote_activate`, values, tokenConfig(token));

export const machineMonitorAction = (values, token) =>
	axios.put(`${remoteRoot}/monitoring`, values, tokenConfig(token));

export const stopMachineAction = (values, token) => axios.post(`${remoteRoot}/remote_stop`, values, tokenConfig(token));

// universal query
export const typesLookupAction = () => axios.get(`${remoteRoot}/template/lookup`, tokenConfig());

export const allMachinesAction = (operatorId, outletId, coin, token) =>
	axios.get(`${remoteRoot}/fetch?&operatorId=${operatorId}&outletId=${outletId}&coin=${coin}`, tokenConfig(token));

// ********************************* machine reports *********************************
// report reducer actions
const reportRoot = localhost ? '/api/report/sales/v1' : `${urlPicker}/api/report/sales/v1`;
const errorReportRoot = localhost ? '/api/report/errorcode/v1' : `${urlPicker}/api/report/errorcode/v1`;
const reportRootV2 = localhost ? '/api/report/sales/v2' : `${urlPicker}/api/report/sales/v2`;

export const getPaymentTypesAction = (token) => axios.get(`${reportRoot}/epayment/methods`, tokenConfig(token));

export const getTerminalTypesAction = (token) => axios.get(`${reportRoot}/terminal/methods`, tokenConfig(token));

export const getOverallEpaymentTypesAction = (token) => axios.get(`${reportRoot}/overallEpayment/methods`, tokenConfig(token));

export const getDNGPaymentTypesAction = (token) => axios.get(`${reportRoot}/dng/methods`, tokenConfig(token));

export const getCostingPaymentTypesAction = (token) => axios.get(`${reportRoot}/costing/methods`, tokenConfig(token));

export const transReportAction = (values, token) =>
	axios.post(`${reportRoot}/transactions`, values, tokenConfig(token));

export const voidTransactionAction = (cycleIds, isVoid, token) =>
	axios.post(`${reportRoot}/void-transaction`, { cycleIds, void: isVoid }, tokenConfig(token));

export const voidVendingAction = (transactionId, isVoid, token) =>
	axios.post(`${reportRoot}/void-vending`, { transactionId, void: isVoid }, tokenConfig(token));

export const saveColumnVisibilityAction = (hiddenColumns, token) =>
	axios.post(`${reportRoot}/update-column-visibility`, { hiddenColumns }, tokenConfig(token));

export const fetchColumnVisibilityAction = (token) =>
	axios.get(`${reportRoot}/fetch-column-visibility`, tokenConfig(token));

// Save favorite search
export const saveFavoriteSearchAction = (favoriteSearch, token) =>
	axios.post(`${reportRoot}/save-favorite-search`, favoriteSearch, tokenConfig(token));

// Fetch favorite search
export const fetchFavoriteSearchAction = (token) =>
	axios.get(`${reportRoot}/fetch-favorite-search`, tokenConfig(token));

export const summaryReportAction = (values, token) =>
	axios.post(`${reportRootV2}/sales_summary`, values, tokenConfig(token));

export const summaryReportOverallAction = (values, token) =>
	axios.post(`${reportRootV2}/sales_summary/overall`, values, tokenConfig(token));

export const epayReportAction = (values, token) => axios.post(`${reportRoot}/epayment`, values, tokenConfig(token));
export const manualPayReportAction = (values, token) =>
	axios.post(`${reportRoot}/manualpay`, values, tokenConfig(token));
export const terminalReportAction = (values, token) => axios.post(`${reportRoot}/terminal`, values, tokenConfig(token));
export const overallEpaymentReportAction = (values, token) => axios.post(`${reportRootV2}/overallEpayment`, values, tokenConfig(token));

export const dngReportAction = (values, token) => axios.post(`${reportRoot}/dng`, values, tokenConfig(token));

export const vendingReportAction = (values, token) => axios.post(`${reportRoot}/vending`, values, tokenConfig(token));

export const costingReportAction = (values, token) => axios.post(`${reportRoot}/costing`, values, tokenConfig(token));


export const errorcodeReportAction = async (values, token) =>
	await axios.post(`${errorReportRoot}/fetch`, values, tokenConfig(token));

export const billReportAction = (values, token) => axios.post(`${reportRoot}/bill`, values, tokenConfig(token));

// ********************************* review feedback *********************************
export const machineSalesTransReportAction = (values, token) =>
	axios.post(`${reportRoot}/machine`, values, tokenConfig(token));

export const hourlySalesTransReportAction = (values, token) =>
	axios.post(`${reportRoot}/sales/hourly`, values, tokenConfig(token));

export const salesByMachineTypeAction = (values, token) =>
	axios.post(`${reportRoot}/machine_type`, values, tokenConfig(token));

// systemErrorReportAction
const errorStatusRoot = localhost ? '/api/report/errorcode/v1' : `${urlPicker}/api/report/errorcode/v1`;
export const systemErrorReportAction = (values, token) =>
	axios.post(`${errorStatusRoot}/alertsystem/fetch`, values, tokenConfig(token));

export const systemErrorReportUpdateAction = (values, token) =>
	axios.put(`${errorStatusRoot}/alertsystem/status/update`, values, tokenConfig(token));

//todo

// reviewDashReducer reducer actions
const reviewsRoot = localhost ? '/api/feedback/v1' : `${urlPicker}/api/feedback/v1`;
export const getReviewsAction = (query, token) => axios.get(`${reviewsRoot}/fetch?${query}`, tokenConfig(token));

export const resolveReviewAction = (reqBody, token) => axios.put(`${reviewsRoot}/resolve`, reqBody, tokenConfig(token));

export const sendReplyAction = (reqBody, token) => axios.post(`${reviewsRoot}/reply`, reqBody, tokenConfig(token));
export const getNotificationsAction = (token) => {
	return axios.get(`${reviewsRoot}/notifications`, tokenConfig(token));
};

export const markNotificationAsReadAction = (id, token) => {
	return axios.put(`${reviewsRoot}/notifications/read`, { id }, tokenConfig(token));
}

export const markAllNotificationsAsReadAction = (token) => {
	return axios.put(`${reviewsRoot}/notifications/readall`, {}, tokenConfig(token));
}

// ********************************* sales data *********************************
// sales data reducer actions
const salesRoot = localhost ? '/api/report/sales/v1' : `${urlPicker}/api/report/sales/v1`;
const salesRootV2 = localhost ? '/api/report/sales/v2' : `${urlPicker}/api/report/sales/v2`;

export const annualSalesReportAction = (reqBody, token) =>
	axios.post(`${salesRoot}/annual`, reqBody, tokenConfig(token));

export const annualSalesReportActionv2 = (reqBody, token) =>
	axios.post(`${salesRootV2}/totalAnnual`, reqBody, tokenConfig(token));

export const totalDailySalesByMonthAction = (reqBody, token) =>
	axios.post(`${salesRootV2}/monthly`, reqBody, tokenConfig(token));

export const totalOutletSalesByYearAction = (year, operatorId, token) =>
	axios.get(`${salesRootV2}/type/outlet?year=${year}&operatorId=${operatorId}`, tokenConfig(token));

export const todaySalesAction = (operatorId, token) =>
	axios.get(`${salesRoot}/today/outlets?operatorId=${operatorId}`, tokenConfig(token));

export const totalOutletSalesMonthAction = (year, month, operatorId, token) =>
	axios.get(`${salesRootV2}/type/outlet?year=${year}&month=${month}&operatorId=${operatorId}`, tokenConfig(token));

export const annualBillReportAction = (year, operatorId, token) =>
	axios.post(`${salesRoot}/bill/outlet`, { year, operatorId }, tokenConfig(token));

export const billReportMonthAction = (year, month, operatorId, token) =>
	axios.post(`${salesRoot}/bill/outlet`, { year, operatorId, month }, tokenConfig(token));

export const dailySalesReportAction = (date, operatorId, token) =>
	axios.get(`${salesRoot}/type/outlet?date=${date}&operatorId=${operatorId}`, tokenConfig(token));

export const dailySalesWithUtilAction = (date, operatorId, outletSingleId, token) =>
	axios.post(`${salesRoot}/daily/machine`, { date, operatorId, outletId: outletSingleId }, tokenConfig(token));

//pie , first api for sales performance
export const annualTypeDistributionAction = (reqBody, token) =>
	axios.post(`${salesRootV2}/distribution/machine_type`, reqBody, tokenConfig(token));

//pie , second api for sales performance
export const annualWeightDistributionAction = (reqBody, token) =>
	axios.post(`${salesRootV2}/distribution/machine_weight`, reqBody, tokenConfig(token));

//pie , third api for sales performance
export const monthTypeDistributionAction = (reqBody, token) =>
	axios.post(`${salesRootV2}/distribution/machine_type`, reqBody, tokenConfig(token));

//pie , fourth api for sales performance
export const monthWeightDistributionAction = (reqBody, token) =>
	axios.post(`${salesRootV2}/distribution/machine_weight`, reqBody, tokenConfig(token));

// sum dash reducer actions
export const channelSalesAction = (year, operatorId, token) =>
	axios.get(`${salesRoot}/type/channel?year=${year}&operatorId=${operatorId}`, tokenConfig(token));

export const machineSalesAction = (year, operatorId, token) =>
	axios.get(`${salesRoot}/type/machine?year=${year}&operatorId=${operatorId}`, tokenConfig(token));

export const weeklySalesAction = (year, operatorId, token) =>
	axios.get(`${salesRoot}/weekly?year=${year}&operatorId=${operatorId}`, tokenConfig(token));

export const weeklySalesYearAction = (year, outlets, operatorId, token) =>
	axios.get(`${salesRoot}/weekly/year?year=${year}&outlets=${outlets.join(',')}&operatorId=${operatorId}`, tokenConfig(token));

// requestEndpoints.js
export const weeklySalesByMonthAction = (year, month, outletIds, operatorId, token) =>
	axios.get(`${salesRoot}/weekly/month?year=${year}&month=${month}&outletIds=${outletIds}&operatorId=${operatorId}`, tokenConfig(token));

const sumDRoot = localhost ? '/api/machine/v1' : `${urlPicker}/api/machine/v1`;

// console.log('tokenConfig()', tokenConfig())
export const machineStatusAction = (year, operatorId, token) => {
	return axios.get(`${sumDRoot}/status?year=${year}&operatorId=${operatorId}`, tokenConfig(token));
};

export const outOfServiceMachineStatusAction = (year, operatorId, token) => {
	return axios.get(`${sumDRoot}/outOfService?year=${year}&operatorId=${operatorId}`, tokenConfig(token));
};

export const offlineMachineStatusAction = (year, operatorId, token) => {
	return axios.get(`${sumDRoot}/offlineMachines?year=${year}&operatorId=${operatorId}`, tokenConfig(token));
};

// ********************************* user reducer *********************************
// user reducer actions

const userRoot = localhost ? '/api/auth/v1' : `${urlPicker}/api/auth/v1`;

export const resetPasswordAction = (updatedUser) => axios.put(`${userRoot}/password/reset`, updatedUser);

export const sendResetEmailAction = (body) =>
	axios.get(
		`${userRoot}/password/forget?email=${body.email}&brand=${body.brand}&role=${body.role}&domain=${body.domain}`
	);

export const loginStaffAction = (loginUser) => axios.post(`${userRoot}/staff/login`, loginUser, config);

export const verifyStaffAction = (id, otp) => axios.post(`${userRoot}/staff/verify`, id, otp, config);

export const registerStaffAction = (newUser) => axios.post(`${userRoot}/staff/register`, newUser, config);

export const registerOperatorAction = (newUser) => axios.post(`${userRoot}/operator/register`, newUser, config);

export const verifyOperatorAction = (token) => axios.post(`${userRoot}/verify/account`, { token }, config);

export const loginOperatorAction = (loginUser) => axios.post(`${userRoot}/operator/login`, loginUser, config);

export const verifyOtpOperatorAction = (id, otp) => axios.post(`${userRoot}/operator/verify`, id, otp, config);

export const checkSessionAction = (token) => axios.post(`${userRoot}/session`, { token }, config);

export const changePasswordAction = (values) => axios.put(`${userRoot}/password/reset`, values, config);

export const loyaltyAdminCheckAction = (token) => axios.get(`${userRoot}/loyalty/login`, tokenConfig(token));

export const loginDistributorAction = (values) => axios.post(`${userRoot}/distributor/login`, values, config);

export const verifyDistributorAction = (id, otp) => axios.post(`${userRoot}/distributor/verify`, id, otp, config);

export const fetch2faAction = (token) => axios.get(`${userRoot}/check2fa`, tokenConfig(token));

export const verifyUser2faAction = (sendBody, token) => axios.post(`${userRoot}/user/verify2fa`, sendBody, tokenConfig(token));

// Admin routes
export const loginAdminAction = (loginUser) => axios.post(`${userRoot}/admin/login`, loginUser, config);

// ********************************* Revenue Monster *********************************
const userManagementRoot = localhost ? '/api/management/v1' : `${urlPicker}/api/management/v1`;

export const addNewUserAction = (newUser, token) =>
	axios.post(`${userManagementRoot}/user/create`, newUser, tokenConfig(token));

export const updateUserAction = (updateData, token) =>
	axios.put(`${userManagementRoot}/user/edit`, updateData, tokenConfig(token));

export const updateOutletVisibilityAction = (updateData, token) =>
	axios.put(`${userManagementRoot}/controller/outlet_controller`, updateData, tokenConfig(token));

export const updateOutletVisibilityActionv2 = (updateData, token) =>
	axios.put(`${userManagementRoot}/controller/outlet_controllerv2`, updateData, tokenConfig(token));

export const updateOperatorVisibilityAction = (updateData, token) =>
	axios.put(`${userManagementRoot}/controller/operator_controller`, updateData, tokenConfig(token));

//

export const getUsersListAction = (token, userType) => {
	// Construct the query parameters
	const query = [
	  userType ? `userType=${userType}` : ''
	]
	  .filter(Boolean)
	  .join('&');
  
	// Construct the full URL with query string
	const url = `${userManagementRoot}/user/fetch${query ? `?${query}` : ''}`;
  
	return axios.get(url, tokenConfig(token));
  };

export const getUserRoleListAction = (token) => axios.get(`${userManagementRoot}/role/fetch`, tokenConfig(token));

export const getUserPermissionsAction = (token) =>
	axios.get(`${userManagementRoot}/permissions/fetch`, tokenConfig(token));

export const addUserRoleAction = (newRole, token) =>
	axios.post(`${userManagementRoot}/role/create`, newRole, tokenConfig(token));

export const assignUserRoleAction = (newRole, token) =>
	axios.put(`${userManagementRoot}/role/assign`, newRole, tokenConfig(token));

export const editUserRoleAction = (updateData, token) =>
	axios.put(`${userManagementRoot}/role/edit`, updateData, tokenConfig(token));

export const deleteUserRoleAction = (id) => {
	return axios.delete(`${userManagementRoot}/delete`, {
		...tokenConfig(),
		data: { id },
	});
};

export const syncStaffOutletPermissionsAction = (token) => axios.get(`${userManagementRoot}/controller/permission/staff`, tokenConfig(token));

export const syncDistributorUserOutletPermissionsAction = (token) => axios.get(`${userManagementRoot}/controller/permission/distributoruser`, tokenConfig(token));

export const syncStaffOperatorControllerAction = (token) => axios.get(`${userManagementRoot}/controller/operator/staff`, tokenConfig(token));

export const syncDistributorUserOperatorControllerAction = (token) => axios.get(`${userManagementRoot}/controller/operator/distributoruser`, tokenConfig(token));

export const importDataAction = (newData, token) => axios.post(`${userManagementRoot}/import`, newData, tokenConfig(token));

export const getDataAction = (token) => axios.get(`${userManagementRoot}/checkTerminal`, tokenConfig(token));

export const getTerminalAction = (token) => axios.get(`${userManagementRoot}/getTerminal`, tokenConfig(token));


// monster action urls
const rmRoot = localhost ? '/api/rm/v1' : `${urlPicker}/api/rm/v1`;

export const createRMAction = (RmDetails, token) => axios.post(`${rmRoot}/create`, RmDetails, tokenConfig(token));

export const updateRMAction = (RmDetails, token) => axios.put(`${rmRoot}/rm/update`, RmDetails, tokenConfig(token));

export const updateRazerAction = (RazerDetails, token) => axios.put(`${rmRoot}/razer/update`, RazerDetails, tokenConfig(token));

export const updateDuitnowAction = (DuitnowDetails, token) => axios.put(`${rmRoot}/duitnow/update`, DuitnowDetails, tokenConfig(token));

export const updateGhlAction = (GhlDetails, token) => axios.put(`${rmRoot}/ghl/update`, GhlDetails, tokenConfig(token));

export const fetchRMAction = (operatorId, token) =>
	axios.get(`${rmRoot}/fetch?operatorId=${operatorId}`, tokenConfig(token));

export const tokenkeyAction = (TokenkeyDetails, token) =>
	axios.post(`${rmRoot}/tokenkey`, TokenkeyDetails, tokenConfig(token));

export const fetchTokenkeyAction = (operatorId, token) =>
	axios.get(
		`${rmRoot}/fetch/tokenkey?operatorId=${operatorId}`,
		tokenConfig(token)
	);

export const createDuitnowAction = (DuitnowDetails, token) => axios.post(`${rmRoot}/duitnow/create`, DuitnowDetails, tokenConfig(token));

export const fetchDuitnowAction = (operatorId, token) =>
	axios.get(`${rmRoot}/duitnow/fetch?operatorId=${operatorId}`, tokenConfig(token));

export const tokenkeyDuitnowAction = (tokenkeyDuitnowDetails, token) =>
	axios.post(`${rmRoot}/duitnow/tokenkey`, tokenkeyDuitnowDetails, tokenConfig(token));

export const fetchTokenkeyDuitnowAction = (operatorId, token) =>
	axios.get(
		`${rmRoot}/duitnow/fetch/tokenkey?operatorId=${operatorId}`,
		tokenConfig(token)
	);

export const createAmpersandPayAction = (ampersandPayDetails, token) =>
	axios.post(`${rmRoot}/ampersandpay/create`, ampersandPayDetails, tokenConfig(token));

export const updateAmpersandPayAction = (ampersandPayDetails, token) =>
	axios.put(`${rmRoot}/ampersandpay/update`, ampersandPayDetails, tokenConfig(token));

export const fetchAmpersandPayAction = (operatorId, token) =>
	axios.get(`${rmRoot}/ampersandpay/fetch?operatorId=${operatorId}`, tokenConfig(token));

export const createRazerAction = (RazerDetails, token) => axios.post(`${rmRoot}/razerpay/create`, RazerDetails, tokenConfig(token));

export const fetchRazerAction = (operatorId, token) =>
	axios.get(`${rmRoot}/razer/fetch?operatorId=${operatorId}`, tokenConfig(token));


export const tokenkeyRazerAction = (TokenkeyDetails, token) =>
	axios.post(`${rmRoot}/razer/tokenkey`, TokenkeyDetails, tokenConfig(token));

export const fetchTokenkeyRazerAction = (operatorId, token) =>
	axios.get(
		`${rmRoot}/razer/fetch/tokenkey?operatorId=${operatorId}`,
		tokenConfig(token)
	);

export const createGhlAction = (GhlDetails, token) => axios.post(`${rmRoot}/ghl/create`, GhlDetails, tokenConfig(token));

export const fetchGhlAction = (operatorId, token) =>
	axios.get(`${rmRoot}/ghl/fetch?operatorId=${operatorId}`, tokenConfig(token));


export const tokenkeyGhlAction = (TokenkeyDetails, token) =>
	axios.post(`${rmRoot}/ghl/tokenkey`, TokenkeyDetails, tokenConfig(token));

export const fetchTokenkeyGhlAction = (operatorId, token) =>
	axios.get(
		`${rmRoot}/ghl/fetch/tokenkey?operatorId=${operatorId}`,
		tokenConfig(token)
	);

////// SCAN2PAY OUTLET AND TOKEN CONFIG //////
export const createScan2PayAction = (Scan2PayDetails, token) =>
	axios.post(`${rmRoot}/scan2pay/create`, Scan2PayDetails, tokenConfig(token));

export const updateScan2PayAction = (Scan2PayDetails, token) =>
	axios.put(`${rmRoot}/scan2pay/update`, Scan2PayDetails, tokenConfig(token));

export const fetchScan2PayAction = (operatorId, token) =>
	axios.get(`${rmRoot}/scan2pay/fetch?operatorId=${operatorId}`, tokenConfig(token));

// FETCH SCAN2PAY TOKENKEY
export const fetchTokenkeyScan2PayAction = (operatorId, token) =>
	axios.get(
		`${rmRoot}/scan2Pay/fetch/tokenkey?operatorId=${operatorId}`,
		tokenConfig(token)
	);

// ADD/EDIT SCAN2PAY TOKENKEY
export const tokenkeyScan2PayAction = (TokenkeyDetails, token) =>
	axios.post(`${rmRoot}/scan2Pay/tokenkey`, TokenkeyDetails, tokenConfig(token));

////// KIPLE TOKEN AND OUTLET CONFIG //////
export const fetchTokenkeyKipleAction = (operatorId, token) =>
	axios.get(
		`${rmRoot}/kiple/fetch/tokenkey?operatorId=${operatorId}`,
		tokenConfig(token)
	);

export const tokenkeyKipleAction = (TokenkeyDetails, token) =>
	axios.post(`${rmRoot}/kiple/tokenkey`, TokenkeyDetails, tokenConfig(token));

export const createKipleAction = (KipleDetails, token) =>
	axios.post(`${rmRoot}/kiple/create`, KipleDetails, tokenConfig(token));

export const updateKipleAction = (KipleDetails, token) =>
	axios.put(`${rmRoot}/kiple/update`, KipleDetails, tokenConfig(token));

export const fetchKipleAction = (operatorId, token) =>
	axios.get(`${rmRoot}/kiple/fetch?operatorId=${operatorId}`, tokenConfig(token));

////// HITPAY TOKEN AND OUTLET CONFIG //////
export const fetchTokenkeyHitpayAction = (operatorId, token) =>
	axios.get(
		`${rmRoot}/hitpay/fetch/tokenkey?operatorId=${operatorId}`,
		tokenConfig(token)
	);

export const tokenkeyHitpayAction = (TokenkeyDetails, token) =>
	axios.post(`${rmRoot}/hitpay/tokenkey`, TokenkeyDetails, tokenConfig(token));

export const createHitpayAction = (HitpayDetails, token) =>
	axios.post(`${rmRoot}/hitpay/create`, HitpayDetails, tokenConfig(token));

export const updateHitpayAction = (HitpayDetails, token) =>
	axios.put(`${rmRoot}/hitpay/update`, HitpayDetails, tokenConfig(token));

export const fetchHitpayAction = (operatorId, token) =>
	axios.get(`${rmRoot}/hitpay/fetch?operatorId=${operatorId}`, tokenConfig(token));

////// PAGBANK TOKEN AND OUTLET CONFIG //////
export const fetchTokenkeyPagbankAction = (operatorId, token) =>
	axios.get(
		`${rmRoot}/pagbank/fetch/tokenkey?operatorId=${operatorId}`,
		tokenConfig(token)
	);

export const tokenkeyPagbankAction = (TokenkeyDetails, token) =>
	axios.post(`${rmRoot}/pagbank/tokenkey`, TokenkeyDetails, tokenConfig(token));

export const createPagbankAction = (PagbankDetails, token) =>
	axios.post(`${rmRoot}/pagbank/create`, PagbankDetails, tokenConfig(token));

export const updatePagbankAction = (PagbankDetails, token) =>
	axios.put(`${rmRoot}/pagbank/update`, PagbankDetails, tokenConfig(token));

export const fetchPagbankAction = (operatorId, token) =>
	axios.get(`${rmRoot}/pagbank/fetch?operatorId=${operatorId}`, tokenConfig(token));

////// 2C2P TOKEN AND OUTLET CONFIG //////
export const fetchTokenkey2c2pAction = (operatorId, token) =>
	axios.get(
		`${rmRoot}/2c2p/fetch/tokenkey?operatorId=${operatorId}`,
		tokenConfig(token)
	);

export const tokenkey2c2pAction = (TokenkeyDetails, token) =>
	axios.post(`${rmRoot}/2c2p/tokenkey`, TokenkeyDetails, tokenConfig(token));

export const create2c2pAction = (TwoCTwoPDetails, token) =>
	axios.post(`${rmRoot}/2c2p/create`, TwoCTwoPDetails, tokenConfig(token));

export const update2c2pAction = (TwoCTwoPDetails, token) =>
	axios.put(`${rmRoot}/2c2p/update`, TwoCTwoPDetails, tokenConfig(token));

export const fetch2c2pAction = (operatorId, token) =>
	axios.get(`${rmRoot}/2c2p/fetch?operatorId=${operatorId}`, tokenConfig(token));


// role list && admin routes action urls
const adminRoot = localhost ? '/api/management/v1' : `${urlPicker}/api/management/v1`;
export const getRoleListAction = (token) => axios.get(`${adminRoot}/role/fetch`, tokenConfig(token));

export const getPermissionsAction = (token) => axios.get(`${adminRoot}/permissions/fetch`, tokenConfig(token));

export const addRoleAction = (newRole, token) => axios.post(`${adminRoot}/role/create`, newRole, tokenConfig(token));

export const assignRoleAction = (newRole, token) => axios.put(`${adminRoot}/role/assign`, newRole, tokenConfig(token));
//todo
export const editRoleAction = (updateData, token) =>
	axios.put(`${adminRoot}/role/edit`, updateData, tokenConfig(token));
//todo
export const deleteRoleAction = (id) => {
	return axios.delete(`${adminRoot}/delete`, {
		...tokenConfig(),
		data: { id },
	});
};

//

export const addUserAction = (reqBody, token) => axios.post(`${adminRoot}/user/create`, reqBody, tokenConfig(token));

export const addClientAction = (reqBody, token) =>
	axios.post(`${adminRoot}/client/create`, reqBody, tokenConfig(token));

export const getClientsAction = (token) => axios.get(`${adminRoot}/client/fetch`, tokenConfig(token));

export const getDistributorsAction = (token) => axios.get(`${adminRoot}/distributor/fetch`, tokenConfig(token));

export const getUsersAction = (token, role) =>
	axios.get(`${adminRoot}/user/fetch${role ? `?userType=${role}` : ''}`, tokenConfig(token));

export const assignOperatorAction = (reqBody, token) =>
	axios.put(`${adminRoot}/operator/assign`, reqBody, tokenConfig(token));

export const assignClientOperatorAction = (reqBody, token) =>
	axios.put(`${adminRoot}/operator/assign_client`, reqBody, tokenConfig(token));

export const addDistributorAction = (reqBody, token) =>
	axios.post(`${adminRoot}/distributor/create`, reqBody, tokenConfig(token));

// ********************************* Utility actions *********************************
// api/utility/v1/reset_volume
const utilityRoot = localhost ? '/api/utility/v1' : `${urlPicker}/api/utility/v1`;

export const getAllGasSensorsAction = (operatorId, outletSingleId, token) =>
	axios.get(`${utilityRoot}/fetch_currentdata?operatorId=${operatorId}&outletId=${outletSingleId}`, tokenConfig(token));

export const getAllChemicalSensorsAction = (operatorId, outletSingleId, token) =>
	axios.get(`${utilityRoot}/fetch_currentdata2?operatorId=${operatorId}&outletId=${outletSingleId}`, tokenConfig(token));

export const getRecordsOfUtilityAction = (reqBody, token) =>
	axios.post(`${utilityRoot}/fetch_records`, reqBody, tokenConfig(token));

export const resetVolumeAction = (reqBody, token) =>
	axios.put(`${utilityRoot}/reset_volume`, reqBody, tokenConfig(token));

export const refillVolumeAction = (reqBody, token) =>
	axios.put(`${utilityRoot}/refill_volume`, reqBody, tokenConfig(token));

export const fetchOutletsControllerAction = async (data, token) =>
	await axios.post(`${adminRoot}/controller/fetch`, data, tokenConfig(token));

export const fetchController = async (data, token) =>
	await axios.post(`${adminRoot}/controller/fetch`, data, tokenConfig(token));

export const fetchControllerv2 = async (data, token) =>
	await axios.post(`${adminRoot}/controller/fetchv2`, data, tokenConfig(token));

// get outlet pinger status
const outletOnlineRoot = localhost ? '/api/status/v1' : `${urlPicker}/api/status/v1`;
export const allOutletsOnlineStatusAction = (reqBody, token) => {
	const { outletId, startDate, endDate, operatorId } = reqBody;

	const queryParams =
		startDate && endDate
			? `outletId=${outletId}&startDate=${startDate}&endDate=${endDate}&operatorId=${operatorId}`
			: `outletId=${outletId}&operatorId=${operatorId}`;
	return axios.get(`${outletOnlineRoot}/pingerlog/fetch?${queryParams}`, tokenConfig(token));
};

// coinCollection Reducer
const coinCollectionRoot = localhost ? '/api/audit/v1/coin_collection' : `${urlPicker}/api/audit/v1/coin_collection`;
const billCollectionRoot = localhost ? '/api/audit/v1/bill_collection' : `${urlPicker}/api/audit/v1/bill_collection`;
export const machineCutoffStartAction = (data, token) =>
	axios.post(`${coinCollectionRoot}/start`, data, tokenConfig(token));

export const machineCutoffAction = (data, token) =>
	axios.post(`${coinCollectionRoot}/cutoff`, data, tokenConfig(token));

export const machineCutoffSubmitAction = (data, token) =>
	axios.put(`${coinCollectionRoot}/submit`, data, tokenConfig(token));

export const machineCutoffEditAction = (data, token) =>
	axios.put(`${coinCollectionRoot}/edit`, data, tokenConfig(token));

export const machineCutoffBillSubmitAction = (data, token) =>
	axios.put(`${billCollectionRoot}/submit`, data, tokenConfig(token));

export const coinCollectionSubmitAction = (data, token) =>
	axios.put(`${coinCollectionRoot}/complete`, data, tokenConfig(token));

export const machineCutoffListAction = (operatorId, token) =>
	axios.get(`${coinCollectionRoot}/dashboard?operatorId=${operatorId}`, tokenConfig(token));

export const getMachineCutoffHistoryAction = (data, token) =>
	axios.get(
		`${coinCollectionRoot}/cutoff/details?coinCollectionId=${data.coinCollectionId}&operatorId=${data.operatorId}`,
		tokenConfig(token)
	);

export const getCutoffStatusAction = (data, token) =>
	axios.get(
		`${coinCollectionRoot}/cutoff/status?id=${data.id}&outletId=${data.outletId}&operatorId=${data.operatorId}`,
		tokenConfig(token)
	);

export const getPreviousCutoffAction = (data, token) =>
	axios.post(
		`${coinCollectionRoot}/cutoff/previousInformation?id=${data.id}&outletId=${data.outletId}&operatorId=${data.operatorId}`,
		{ previousCutoffId: data.previousCutoffId }, // Send previousCutoffId in the body
		tokenConfig(token)
	);


//requestEndpoints.js
// report coin collection
export const getCoinCollectionByOutletAction = (reqBody, token) =>
	axios.post(`${coinCollectionRoot}/report/outlet`, reqBody, tokenConfig(token));

export const getCoinCollectionByMachineAction = (reqBody, token) =>
	axios.post(`${coinCollectionRoot}/report/machine`, reqBody, tokenConfig(token));

export const getBillCollectionReportAction = (reqBody, token) =>
	axios.post(`${billCollectionRoot}/report`, reqBody, tokenConfig(token));

const coinInventory = localhost ? '/api/inventory/v1' : `${urlPicker}/api/inventory/v1`;

export const refillCoinInventoryAction = (reqBody, token) =>
	axios.put(`${coinInventory}/refill_coin`, reqBody, tokenConfig(token));

export const resetCoinInventoryAction = (reqBody, token) =>
	axios.put(`${coinInventory}/reset_coin`, reqBody, tokenConfig(token));

// 
export const getCoinCollectionAction = (outletId, token) =>
	axios.get(`${remoteRoot}/group/fetch?outletId=${outletId}`, tokenConfig(token));

export const addCoinCollectionAction = (newCoinGroup, token) =>
	axios.post(`${remoteRoot}/group/create`, newCoinGroup, tokenConfig(token));

export const assignCoinCollectionAction = (coinGroup, token) =>
	axios.put(`${remoteRoot}/group/assign`, coinGroup, tokenConfig(token));

export const editCoinCollectionAction = (updateData, token) =>
	axios.put(`${remoteRoot}/group/edit`, updateData, tokenConfig(token));

export const deleteCoinCollectionAction = (id) => {
	return axios.delete(`${remoteRoot}/delete`, {
		...tokenConfig(),
		data: { id },
	});
};

// requestEndpoints.js
// ********************************* Chemical Input Stock and Chemical Stock Page  *********************************

const chemicalRoot = localhost ? '/api/inventorystock/v1' : `${urlPicker}/api/inventorystock/v1`;

export const fetchChemicalOutletListAction = ({ outletId, token }) =>
	axios.get(`${chemicalRoot}/set/type/fetch?outletId=${outletId}`, tokenConfig(token));

export const ManageChemicalsAction = (reqBody, token) =>
	axios.post(`${chemicalRoot}/stock/manage`, reqBody, tokenConfig(token));

export const fetchStockLogsAction = ({ outletId, token }) =>
	axios.get(`${chemicalRoot}/stock/logs/fetch?outletId=${outletId}`, tokenConfig(token));

export const fetchStockLogsAction2 = (reqBody, token) => {
	return axios.post(`${chemicalRoot}/stock/logs/fetch2`, reqBody, tokenConfig(token));
}

// requestEndpoints.js
// ********************************* Chemical Stock Setting Page *********************************

const chemicalTypeRoot = localhost ? '/api/chemical/v1/type' : `${urlPicker}/api/chemical/v1/type`;

export const getChemicalTypeAction = (operatorId, token) =>
	axios.get(`${chemicalTypeRoot}/fetch?operatorId=${operatorId}`, tokenConfig(token));

export const addChemicalTypeAction = (newChemicalType, token) => axios.post(`${chemicalTypeRoot}/add`, newChemicalType, tokenConfig(token));

export const assignChemicalTypeAction = (newChemicalType, token) => axios.put(`${chemicalTypeRoot}/assign`, newChemicalType, tokenConfig(token));

export const editChemicalTypeAction = (updateData, token) => axios.put(`${chemicalTypeRoot}/edit`, updateData, tokenConfig(token));

export const deleteChemicalTypeAction = (resData, token) => {
	return axios.delete(`${chemicalTypeRoot}/delete`, {
		...tokenConfig(token),
		data: resData,
	});
};

const costingTypeRoot = localhost ? '/api/costing/v1/type' : `${urlPicker}/api/costing/v1/type`;

export const getCostingTypeAction = (operatorId, token) =>
	axios.get(`${costingTypeRoot}/fetch/costing?operatorId=${operatorId}`, tokenConfig(token));

export const addCostingTypeAction = (newCostingType, token) => axios.post(`${costingTypeRoot}/add`, newCostingType, tokenConfig(token));

export const assignCostingTypeAction = (newCostingType, token) => axios.put(`${costingTypeRoot}/assign`, newCostingType, tokenConfig(token));

export const editCostingTypeAction = (updateData, token) => axios.put(`${costingTypeRoot}/edit`, updateData, tokenConfig(token));

export const deleteCostingTypeAction = (resData, token) => {
	return axios.delete(`${costingTypeRoot}/delete`, {
		...tokenConfig(token),
		data: resData,
	});
};

export const fetchCostingOutletListAction = ({ outletId, token }) =>
	axios.get(`${costingTypeRoot}/fetch/cost?outletId=${outletId}`, tokenConfig(token));

export const ManageCostingAction = (reqBody, token) =>
	axios.post(`${costingTypeRoot}/stock/manage`, reqBody, tokenConfig(token));


const salesTypeRoot = localhost ? '/api/sales/v1/type' : `${urlPicker}/api/sales/v1/type`;

export const getSalesTypeAction = (operatorId, token) =>
	axios.get(`${salesTypeRoot}/fetch/sales?operatorId=${operatorId}`, tokenConfig(token));

export const addSalesTypeAction = (newSalesType, token) => axios.post(`${salesTypeRoot}/add`, newSalesType, tokenConfig(token));

export const assignSalesTypeAction = (newSalesType, token) => axios.put(`${salesTypeRoot}/assign`, newSalesType, tokenConfig(token));

export const editSalesTypeAction = (updateData, token) => axios.put(`${salesTypeRoot}/edit`, updateData, tokenConfig(token));

export const deleteSalesTypeAction = (resData, token) => {
	return axios.delete(`${salesTypeRoot}/delete`, {
		...tokenConfig(token),
		data: resData,
	});
};

export const fetchSalesOutletListAction = ({ outletId, token }) =>
	axios.get(`${salesTypeRoot}/fetch/sales2?outletId=${outletId}`, tokenConfig(token));

export const ManageSalesAction = (reqBody, token) =>
	axios.post(`${salesTypeRoot}/stock/manage/sales`, reqBody, tokenConfig(token));


export const getCostingItemTypesAction = (operatorId, token) => axios.get(`${reportRoot}/costing/item?operatorId=${operatorId}`,  tokenConfig(token));

// ***************************** Machine Efficiency Details ***********************************
const machineDetailsRoot = localhost ? '/api/machine/v1' : `${urlPicker}/api/machine/v1`;

export const fetchMachineDetailsAction = (operatorId, filterMonth, filterYear, paginationNumber, token) =>
	axios.post(`${machineDetailsRoot}/details/fetch`, { operatorId, filterMonth, filterYear, paginationNumber }, tokenConfig(token));